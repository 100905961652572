import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap-icons/font/bootstrap-icons.scss';
import './App.scss';
import React, { Suspense, useEffect, useState } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from '../components/spinner/spinner.component'
import { ToastContainer, Slide } from 'react-toastify';
import Timeout from '../components/Common/Timeout/Timeout';
import { tokenExpiry } from '../endpoints/gateway';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { logout } from '../ducks/gateway/login/actions'
import { useDispatch } from 'react-redux';

const Gateway = React.lazy(() => import('./GatewayPage'));
const Internal = React.lazy(() => import('./AppInternal'));
const Welcome = React.lazy(() => import('./Welcome'));
const SuccessfulRegirationPage = React.lazy(() => import('./SuccessfulRegirationPage'));
const Forgotpwd = React.lazy(() => import('../components/gateway/Forgotpwd'));
const Organization = React.lazy(() => import('../components/gateway/Organization'));
const Verify = React.lazy(() => import('../components/gateway/Verify'));
const Resend = React.lazy(() => import('../components/gateway/Resend'));
// const Quantify = React.lazy(() => import('../components/gateway/Publicpage'));
const QuantifyPublic = React.lazy(() => import('./QuantifyPublic'));

const App = ({ user, menu }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [tokenExpired, settokenExpired] = useState(false)
	axios.interceptors.request.use(request => {
		const AUTH_TOKEN = JSON.parse(localStorage.getItem('USER_INFO'));
		request.headers["Accept"] = "application/json, text/plain, */*";
		// request.headers["Access-Control-Allow-Origin"] = "*";
		request.headers["Authorization"] = `${AUTH_TOKEN.allInfo.accessToken}`;
		let Expiry = tokenExpiry();
		if (Expiry) {
			settokenExpired(true);
		}
		else {
			settokenExpired(false)
			return request
		}

	})

	const closeDialog = () => {
		localStorage.clear();
		settokenExpired(false)
		dispatch(logout());
		setTimeout(() => {
			history.push("/login");
		}, 0)

	}


	return (
		<div className={menu.isOpen ? "drawerOpen h-100" : "drawerClose h-100"}>
			{
				tokenExpired && <Timeout closeDialog={closeDialog} />
			}
			<ToastContainer position="top-right" autoClose={2000} hideProgressBar transition={Slide} />
			<Spinner />
			<Suspense fallback={<div>Loading...</div>} >
				{!user.userInfo.isLoggedIn ?
					(<Switch>
						<Redirect exact from="/" to="/home" />
						<Route exact path="/(login|register|resetpassword|reports|auth)" name="Login Page" render={props => <Gateway {...props} />} />
						<Route exact path="/confirm/:confirmationCode" name="wlecome" render={props => <Welcome {...props} />} />
						<Route exact path="/regconfirm/:confirmationCode" name="wlecome" render={props => <SuccessfulRegirationPage {...props} />} />
						<Route exact path="/forgotpwd" name="ForgotPwd Page" render={props => <Forgotpwd {...props} />} />
						{/* <Route exact path="/organization" name="organization Details" render={props => <Organization {...props} />}/> */}
						<Route exact path="/verify" name="Verify Page" render={props => <Verify {...props} />} />
						<Route exact path="/resend" name="Resend Page" render={props => <Resend {...props} />} />
						{/* <Route exact path="/quantify" name="public page" render={props => <Quantify {...props} />} /> */}
						<Route exact path="/(home|QATBHome|terms|privacy|demoregister)" name="public page" render={props => <QuantifyPublic {...props} />} />
						<Redirect to="/login" />
					</Switch>) :
					(<Switch>
						<Redirect exact from="/" to="/dashboard" />
						<Redirect exact from="/home" to="/dashboard" />
						<Route exact path="/(login|register)" name="Login Page" render={props => <Gateway {...props} />} />
						<Route
							key="internal"
							exact={false}
							path="/"
							component={Internal}
						/>
						<Redirect to="/login" />
					</Switch>)
				}
			</Suspense>

		</div>
	)

};

const mapStateToProps = (state) => (

	{ user: state.user, menu: state.menu }
);

export default connect(mapStateToProps, null)(App);

