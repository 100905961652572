import {
    fetchApi,
    getProjetManagementApi,
    getTescaseManagementApi,
    getReportManagementApi,
    getManagementApi,
    getplatformadminApi,
    getjmx,
    getSubscribeApi,
    getProductSubscribeApi,
    fetchTestcaseConfigurationDetails,
    getQuantifyResultApi,
    getapiRecording
} from '../utils/fetch-api';

import { authApi } from '../utils/authapi'
import { recordApi } from '../utils/recordapi'

import jwt_decode from "jwt-decode";
import { method } from 'lodash';


export function login(param) {
    return authApi(`/login`, {
        method: 'POST',
        body: {
            userName: param.userName.toLowerCase(),
            password: param.password
        }
    });
}

//loginOP
export function loginOP(param) {
   
    return authApi(`/identity`, {
        method: 'POST',
        body: {
            access_token: param.access_token,
            auth_provider: param.auth_provider
        }
    });
}

export function signup(details) {
    return authApi(`/signup`, {
        method: 'POST',
        body: details
    });
}

export function resendEmail(userName) {
    return authApi(`/resend`, {
        method: 'POST',
        body: {
            "userName": userName
        }
    });
}

export function createcode(userName, password) {
    return authApi(`/forgetPassword`, {
        method: 'POST',
        body: {
            userName,
            password
        }
    });
}

export function resetpassword(userName, password, confirmCode) {
    return authApi(`/confirmPassword`, {
        method: 'POST',
        body: {
            userName,
            password,
            confirmCode
        }
    });
}

export function signupComplete(details) {
    return authApi(`/api/signup/cognito`, {
        method: 'PATCH',
        headers: {
            'Cookie': '_ga=GA1.1.924631854.1644574619; _gid=GA1.1.665798991.1651565894; mp_554cd4b7d1891b6d43ff3853b39f615c_mixpanel=%7B%22distinct_id%22%3A%20%22gvinoth76%2B450%40gmail.com%22%2C%22%24device_id%22%3A%20%2217ee84931d86df-0e55cbc7002cda-576153e-144000-17ee84931d910ee%22%2C%22%24initial_referrer%22%3A%20%22%24direct%22%2C%22%24initial_referring_domain%22%3A%20%22%24direct%22%2C%22%24user_id%22%3A%20%22gvinoth76%2B471%40gmail.com%22%7D'
        },
        body: details
    });
}

export function getCompanyDetails(userId) {
    return fetchApi(`/getCompany/${userId}`, {
        method: 'get'
    });
}



export function domainCreate(details, reqMethod) {
    return getProjetManagementApi(`/api/domain/feed`, {
        method: reqMethod,
        body: details
    });
}

export function getDomain(details) {
    return getProjetManagementApi(`/api/domain/allByUser/${details.userId}`, {
        method: 'get'
    });
}



export function projectCreate(details, reqMethod) {
    return getProjetManagementApi(`/api/project/feed`, {
        method: reqMethod,
        body: details
    });
}

export function getProject(details) {
    return getProjetManagementApi(`/api/project/allByDomain/${details.doaminId}`, {
        method: 'get'
    });
}

export function getProjectbyuserId(details) {
    return getProjetManagementApi(`/api/project/allByUserIDNew/${details.userId}`, {
        method: 'get'
    });
}

//Whitelabelling
export async function getRunStatusTestCase(runId) {
    return await getProjetManagementApi(`/api/dashboard/getRunStatus/${runId}`, {
        method: 'get'
    });
}

export function getJmx(details) {
    return getTescaseManagementApi(`/api/attachment/view`, {
        method: 'get',
        params: details
    });
}

export function getThread(details) {
    return getTescaseManagementApi(`/api/testcase/viewThreadGroups/${details.testcaseId}`, {
        method: 'get'
    });
}

export function getViewAttachment(details) {

    return getTescaseManagementApi(`/api/testcase/viewTestcaseAttachment/${details.testcaseId}`, {
        method: 'get',

    });
}

export function getAllScenarios(details) {

    return getTescaseManagementApi(`/api/testcase/viewAllScenarios/${details.testcaseId}`, {
        method: 'get',

    });
}

export function addScenario(details) {
    return getTescaseManagementApi(`/api/testcase/saveThreadGroup`, {
        method: 'post',
        body: details
    });
}


export function getCSVSplitDetails(details) {
    return getManagementApi(`/api/v1/distributeCSV/${details.testcaseId}/${details.noOfSlave}/${details.userCount}`, {
        method: 'get'
    });
}


export function getAllTestCase(details) {
    return getTescaseManagementApi(`/api/testcase/allTestcases/${details.projectId}`, {
        method: 'get',
        params: details
    });
}

export async function getTestcaseById(details) {
    return getTescaseManagementApi(`/api/testcase/viewThreadGroups/${details.testcaseId}`, {
        method: 'get'
    });
}



//Reading JMeter result data from S3 Buckets
export function getReport(details) {
    return getReportManagementApi(`/api/v2/result/${details.runId}`, {
        method: 'get'
    });
}
//POST - Old method of reading JMeter result json file from local directroy
export async function getReportDetails(details) {
    return await getReportManagementApi(`/api/v2/view`, {
        method: 'POST',
        body: details
    });
}

export async function getClientProfile(userId) {
    return await getReportManagementApi(`/api/v1/client/profileview/${userId}`, {
        method: 'get'
    });
}

export async function clientProfileCreate(details) {
    return await getReportManagementApi(`/api/v1/client/fileupload`, {
        method: 'POST',
        body: {
            details: details
        }
    });
}

export async function clientProfileUpdate(formData) {
    return await getReportManagementApi(`/api/v1/client/createclientprofile`, {
        method: 'POST',
        body: formData
    });
}

export function getAllregion() {
    return getManagementApi(`/api/v1/getClusterAndRegion`, {
        method: "GET"
    })
}

export function evnautoCalculation(vusers) {
    return getManagementApi(`/api/v1/autoCalculation/${vusers}/Small`, {
        method: "GET"
    })
}

export function viewrun(details) {
    return getManagementApi(`/api/v1/viewRuns/${details.testcaseId}`, {
        method: 'get'
    });
}

export function viewallrun(details) {
    return getProjetManagementApi(`/api/dashboard/getAllRunsForUser/${details.testcaseId}`, {
        method: 'get'
    });
}

export function stoprun(details) {
    return getManagementApi(`/api/v1/stopRun/${details.runId}/${details.currentDateTime}`, {
        method: 'GET'
    });
}

/*----*/

export function startenvironment(envname) {
    return getManagementApi(`/api/v1/startCommand/${envname}/1`, {
        method: 'get'
    })
}

export function createenvironment(details) {
    return getManagementApi(`/api/v1/loadKuberCommands/${details.slave}/${details.environmentname}/${details.userId}`, {
        method: 'get'
    });

}

export function viewenvironment(userId) {
    return getManagementApi(`/api/v1/getAllViewEnvironment/${userId}`, {
        method: 'get'
    });
}

export function deleteenvironment(details) {
    return getManagementApi(`/api/v1/destoryService/${details.envId}`, {
        method: 'get'
    });
}

export function fetchmasterurl(details) {
    return getManagementApi(`/api/v1/getMasterUrl/${details.envname}`, {
        method: 'get'
    });
}
/*----*/
//runtestcase handlestartrun

export function startRun(url, payload) {
    return getManagementApi(`${url}`, {
        method: "POST",
        body: payload
    })
}
//subscription details

export function getUserProducts(details) {
    return getplatformadminApi(`/getUserProducts/${details.userId}`, {
        method: "GET"
    })
}


export function getSubscriptionUserDetails(url) {
    return getplatformadminApi(`${url}`, {
        method: "GET"
    })
}

export function getUserDetails(details) {
    return fetchApi(`/getUserDetails/${details.userEmail}`, {
        method: "GET"
    })
}

export function runLoadCommands(payload) {
    return getManagementApi(`/api/v1/loadConfigurationCommands`, {
        method: "POST",
        body: payload
    })
}

export function getServerStatus(runId) {
    return getManagementApi(`/api/v1/getPodStatus/${runId}`, {
        method: "GET"
    })
}

export function getTestcases(projectId) {
    return getTescaseManagementApi(`/api/testcase/allTestcases/${projectId}`, {
        method: "GET"
    })
}

export function uploadFiles(formData) {
    return getTescaseManagementApi(`/api/attachment/uploadfiles`, {
        method: "POST",
        body: formData
    })
}

export function uploadCSVFiles(formData, type) {
    return getTescaseManagementApi(`/api/attachment/uploadfilesCSV/${type}`, {
        method: "POST",
        body: formData
    })
}

export function createTestcase(testcaseData) {
    return getTescaseManagementApi(`/api/testcase/feed`, {
        method: "POST",
        body: testcaseData
    })
}

export function saveCompanyDetails(data, method) {
    return fetchApi(`/company`, {
        method: method,
        body: data
    })
}

export function fetchCompanyUser(id) {
    return fetchApi(`/getCompany/${id}`, {
        method: "GET"
    })
}

export function getEditjmx(jmxId) {
    return getjmx(`/api/attachment/views/${jmxId}`, {
        method: "GET"
    })
}

export function updateJmx(payload) {
    return getTescaseManagementApi(`/api/testcase/saveCSVParameter`, {
        method: "POST",
        body: payload
    })
}

export function deleteData(id, apiurl) {
   
    return getProjetManagementApi(`${apiurl}/${id}`, {
        method: 'DELETE'
    })
}

export function saveSubscribe(payload) {
    return getSubscribeApi(`/saveSubscriptionInfo`, {
        method: 'POST',
        body: payload
    })
}

export function getSubscribe(productId, subscriptionModeId) {
    return getSubscribeApi(`/getSubscriptionsForProduct/${productId}/${subscriptionModeId}`, {
        method: 'GET'
    })
}



export function getAllProducts(userId) {
    return getProductSubscribeApi(`/subscription/allProductsDetails/${userId}`, {
        method: 'GET'
    })
}

export function sendPlatformInvite(userEmail) {
    return getProductSubscribeApi(`/invite/sendPlatformInvite/${userEmail}/1`, {
        method: 'POST'
    })
}

export function getMappedUsers(userEmail) {
    return getProductSubscribeApi(`/platform/getMappedUsers/${userEmail}`, {
        method: 'GET'
    })
}

export function getRoles() {
    return getProductSubscribeApi(`/platform/getRoles`, {
        method: 'GET'
    })
}
//Hitting another service
export async function getTestcaseConfigurationDetails(testcaseId) {
    return await fetchTestcaseConfigurationDetails(`${process.env.REACT_APP_TESTCASEMANAGEMENT}/api/testcase/viewThreadGroups/${testcaseId}`, {
        method: 'GET'
    })
}

export async function getTestcaseScenarioNames(runId) {
    return await fetchTestcaseConfigurationDetails(`${process.env.REACT_APP_MANAGEMENT}/api/v1/getLoadConfigurationDetails/${runId}`, {
        method: 'GET'
    })
}


export function deleteMapperUser(id) {
    return getProductSubscribeApi(`/platform/deleteMappedProduct/${id}`, {
        method: 'DELETE'
    })
}

export function getProducts() {
    return getProductSubscribeApi(`/platform/getProducts`, {
        method: 'GET'
    })
}

export function getProductsCounts(userEmail) {
    return getProductSubscribeApi(`/platform/getProductsDetails/${userEmail}`, {
        method: 'GET'
    })
}

export function getUsersInOrg(userEmail) {
    //`/platform/getUsersInOrg/${userEmail}`
    return getProductSubscribeApi(`/platform/getAllUsers`, {
        method: 'GET'
    })
}

export function inviteProductMemebr(data) {
    return getProductSubscribeApi(`/platform/userMapProductMember`, {
        method: 'GET',
        body: data
    })
}

export function getOrgProductMemebr(companyId) {
    return getProductSubscribeApi(`/platform/orgProducts/${companyId}`, {
        method: 'GET',
    })
}
export function getproductByUser(userId, productId) {
    return getProductSubscribeApi(`/platform/productByUser/${userId}/${productId}`, {
        method: 'GET',
    })
}

export function getSubscriptedProducts(companyId) {
    return getProductSubscribeApi(`/platform/getSubscriptedProducts/${companyId}`, {
        method: 'GET',
    })
}

export function productMap(payload) {
    return getProductSubscribeApi(`/subscription/addUserToProduct`, {
        method: 'POST',
        body: payload
    })
}

export function updateUser(payload) {
    return fetchApi(`/updateUser`, {
        method: 'PUT',
        body: payload
    })
}

export function updatePassword(payload) {
    return fetchApi(`/updatePassword`, {
        method: 'POST',
        body: payload
    })
}

export function fetchTestcases(details) {
    return getTescaseManagementApi(`${details}`, {
        method: 'GET'
    })
}

export function saveTestcases(url, payload, method) {
    return getTescaseManagementApi(`${url}`, {
        method: method,
        body: payload
    })
}

export function DeleteTestcases(details) {
    return getTescaseManagementApi(`${details}`, {
        method: 'DELETE'
    })
}


export function getAllProjectsByUser(url, method) {
    return getProjetManagementApi(`${url}`, {
        method: method
    })
}
export function getAllTestcasesUnderProject(url) {
    return getTescaseManagementApi(`${url}`, {
        method: 'GET'
    })
}

export function topFiveResultFilter(url, method, payload) {
    return getProjetManagementApi(`${url}`, {
        method: method,
        body: payload
    })
}

export function quantifyReport(url) {
    return getQuantifyResultApi(`${url}`, {
        method: "GET"
    })
}

export function utilizationReport(url) {
    return getManagementApi(`${url}`, {
        method: "GET"
    })
}

export function getSubscribePlan() {
    return getSubscribeApi(`/getSubscriptions`, {
        method: 'GET'
    })
}

export function saveSubscribePlan(url, method, payload) {
    return getSubscribeApi(`${url}`, {
        method: method,
        body: payload
    })
}

export function getSubscriptionsModes() {
    return getSubscribeApi(`/getSubscriptionsModes`, {
        method: 'GET'
    })
}

export function planDetails(url) {
    return getSubscribeApi(`${url}`, {
        method: 'GET'
    })
}

export function allProductSubscriptionDetail(url) {
    return getSubscribeApi(`${url}`, {
        method: 'GET'
    })
}

export function getproductUsageDetails(url) {
    return getSubscribeApi(`${url}`, {
        method: 'GET'
    })
}

export function subscriptionPlanDetails(url) {
    return getSubscribeApi(`${url}`, {
        method: 'GET'
    })
}

export function subscriptionPendingDetails(url) {
    return getSubscribeApi(`${url}`, {
        method: 'GET'
    })
}

export function subscriptionApprove(url, payload) {
    return getSubscribeApi(`${url}`, {
        method: 'POST',
        body: payload
    })
}



export function apiRecording(url, payload) {
    return recordApi(url, payload)
}

export function saveMonthlySubscribePlan(url, method, payload) {
    return getSubscribeApi(`${url}`, {
        method: method,
        body: payload
    })
}

export function saveProductSubscribe(url, payload, method) {
    return getSubscribeApi(`${url}`, {
        method: method,
        body: payload
    })
}

export function getLicenseValidation(productId, licenseId) {
    return getapiRecording(`http://65.2.23.32:8666/api/subscription/license/${productId}/${licenseId}`, {
        method: 'GET'
    })
}

export function saveDemoForm(url, method, body) {
    return recordApi(`${url}`, {
        method: method,
        body: body
    })
}

export function getCmsData(url) {
    return recordApi(`${process.env.REACT_APP_CMS}${url}`, {
        method: "GET",
    })
}

export function getStrapiCmsData(url) {
    return recordApi(`${process.env.REACT_APP_STRAPICMS}${url}`, {
        method: "GET",
    })
}


export function getAppdynamicsData(url) {
    return getManagementApi(`${url}`, {
        method: "GET"
    })
}

export function saveAppdynamicsData(url, body) {
    return getManagementApi(`${url}`, {
        method: "POST",
        body: body
    })
}

export function getCPUDataRun(url) {
    return getManagementApi(`${url}`, {
        method: "GET"
    })
}

export function getMemoryDataRun(url) {
    return getManagementApi(`${url}`, {
        method: "GET"
    })
}

export function tokenExpiry() {
    
    let token = JSON.parse(localStorage.getItem('USER_INFO'));
    if(token && token.allInfo && token.allInfo.accessToken){
        let decodedToken = jwt_decode(token.allInfo.accessToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            return true
        } else {
            return false;
        }
    }else{
        return false;
    }
    
}

export function generateLicenceKey(url, payload) {
    return getSubscribeApi(`${url}`, {
        method: 'POST',
        body: payload
    })
}